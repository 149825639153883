import * as React from 'react'

function PhoneIcon(props) {
  return (
    <svg
      width={10}
      height={14}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.333.333a2 2 0 00-2 2v9.334a2 2 0 002 2h5.334a2 2 0 002-2V2.333a2 2 0 00-2-2H2.333zm-.666 2c0-.368.298-.666.666-.666h5.334c.368 0 .666.298.666.666v9.334a.667.667 0 01-.666.667H2.333a.667.667 0 01-.666-.667V2.333zm2 7.334a.667.667 0 000 1.333h2.666a.667.667 0 000-1.333H3.667z"
        fill="#000"
      />
    </svg>
  )
}

export default PhoneIcon
