import React from 'react'

function SearchIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M18.176 15.7618L22.5 20c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0l-4.324-4.2382C15.3145 18.3183 13.4869 19 11.5 19 6.80558 19 3 15.1944 3 10.5 3 5.80558 6.80558 2 11.5 2c4.6944 0 8.5 3.80558 8.5 8.5 0 1.9869-.6817 3.8145-1.824 5.2618zm-2.0162-.7301C17.2986 13.8609 18 12.2624 18 10.5 18 6.91015 15.0899 4 11.5 4 7.91015 4 5 6.91015 5 10.5c0 3.5899 2.91015 6.5 6.5 6.5 1.7624 0 3.3609-.7014 4.5317-1.8402.0192-.023.0396-.0453.0612-.0669.0216-.0216.0439-.042.0669-.0612z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SearchIcon
