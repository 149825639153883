import React from 'react'

function ArrowDownIcon(props) {
  return (
    <svg width={16} height={10} viewBox="0 0 16 10" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.292893.792893c-.3905241.390527-.3905242 1.023687 0 1.414217l6.999997 7c.39053.39052 1.02369.39052 1.41422 0l6.99999-7c.3905-.39053.3905-1.02369 0-1.414216-.3905-.390524-1.0237-.390524-1.4142 0L8 7.08579 1.70711.792893C1.31658.402369.683418.402369.292893.792893z"
        fill="#6D6D6D"
      />
    </svg>
  )
}

export default ArrowDownIcon
