import * as React from 'react'

function UsersIcon(props) {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.333 3.723c0-.612.18-1.12.462-1.46.272-.329.666-.54 1.205-.54.54 0 .934.211 1.205.54.283.34.462.848.462 1.46 0 .595-.205 1.207-.537 1.657-.332.451-.736.676-1.13.676-.394 0-.797-.225-1.13-.676a2.875 2.875 0 01-.537-1.657zM6 .39c-.934 0-1.706.386-2.233 1.023C3.251 2.036 3 2.863 3 3.723c0 .878.295 1.766.797 2.448C4.297 6.85 5.06 7.39 6 7.39s1.703-.539 2.204-1.22C8.705 5.49 9 4.6 9 3.724c0-.86-.251-1.687-.767-2.31C7.706.776 6.933.39 6 .39zm4 0a.667.667 0 100 1.333c.54 0 .934.211 1.205.54.283.34.462.848.462 1.46 0 .595-.205 1.207-.537 1.657-.332.451-.736.676-1.13.676a.667.667 0 100 1.334c.94 0 1.703-.539 2.204-1.22.501-.68.796-1.57.796-2.447 0-.86-.251-1.687-.767-2.31C11.706.776 10.933.39 10 .39zM4.138 7.918c.26.26.26.683 0 .943l-1.943 1.943a.667.667 0 00-.195.471v.448c0 .368.299.667.667.667h6.666a.667.667 0 00.667-.667v-.448a.667.667 0 00-.195-.471L7.862 8.861a.667.667 0 11.943-.943l1.943 1.943a2 2 0 01.585 1.414v.448a2 2 0 01-2 2H2.667a2 2 0 01-2-2v-.448a2 2 0 01.586-1.414l1.942-1.943c.26-.26.683-.26.943 0zm9.035.038a.667.667 0 10-1.012.868l1.679 1.959c.103.12.16.274.16.433v.507a.667.667 0 01-.667.667h-.666a.667.667 0 000 1.333h.666a2 2 0 002-2v-.507a2 2 0 00-.481-1.301l-1.68-1.96z"
        fill="#000"
      />
    </svg>
  )
}

export default UsersIcon
