import * as React from 'react'

function FireIcon(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.702 10.053c.585.586 1.513.608 2.07.05.558-.557 1.344-2.293.758-2.878-.586-.586-2.321.2-2.879.757-.558.558-.535 1.485.05 2.071z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.519 2.189c-.055-.342-.215-.7-.517-.948a1.22 1.22 0 00-1.207-.2c-1 .355-2.732 1.112-4.055 2.435l-.092.092c-2.25 2.25-2.373 5.86-.172 8.062 2.26 2.26 5.929 1.96 8.061-.173a.69.69 0 00.054-.06c2.482-2.653 2.919-6.3 3.135-8.357.116-1.097-.995-1.802-1.934-1.382l-3.22 1.285a6.296 6.296 0 00-.053-.754zM3.726 4.463c1.059-1.06 2.482-1.72 3.414-2.064l.002.012c.04.255.043.876.043 1.558a.697.697 0 00.956.648l4.184-1.67.01-.003c-.223 2.095-.657 5.28-2.798 7.538a.713.713 0 00-.024.027c-1.667 1.626-4.419 1.767-6.051.134-1.612-1.611-1.58-4.337.172-6.089l.092-.091z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FireIcon
