import * as React from 'react'

function LogoutIcon(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.667 0a2 2 0 00-2 2v8a2 2 0 002 2H6a2 2 0 002-2v-.667a.667.667 0 00-1.333 0V10a.667.667 0 01-.667.667H2.667A.667.667 0 012 10V2c0-.368.299-.667.667-.667H6c.368 0 .667.299.667.667v.667a.667.667 0 101.333 0V2a2 2 0 00-2-2H2.667z"
        fill="#000"
      />
      <path
        d="M11.805 5.529c.26.26.26.682 0 .942l-2 2a.667.667 0 01-.943-.942l.862-.862H4a.667.667 0 010-1.334h5.724l-.862-.862a.667.667 0 11.943-.942l2 2z"
        fill="#000"
      />
    </svg>
  )
}

export default LogoutIcon
