import React from 'react'

function CheckIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.707 4.293a1 1 0 010 1.414l-13 13a1 1 0 01-1.475-.067l-5-6a1 1 0 011.536-1.28l4.3 5.159L20.292 4.293a1 1 0 011.414 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CheckIcon
