import React from 'react'

function UndoIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12a7 7 0 0112.25-4.63c.187.212.357.423.512.63H16a1 1 0 100 2h4a1 1 0 001-1V5a1 1 0 10-2 0v1.34A8.98 8.98 0 0012 3a9 9 0 108.716 11.25 1 1 0 10-1.937-.5A7 7 0 015 12z"
        fill="currentColor"
      />
    </svg>
  )
}

export default UndoIcon
