import React from 'react'

function PlusIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20a1 1 0 102 0v-7h7a1 1 0 100-2h-7V4a1 1 0 10-2 0v7H4a1 1 0 100 2h7v7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PlusIcon
