import * as React from 'react'

function Explore(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C4.34315 3 3 4.34315 3 6V8C3 9.65685 4.34315 11 6 11H8C9.65685 11 11 9.65685 11 8V6C11 4.34315 9.65685 3 8 3H6ZM5 6C5 5.44772 5.44772 5 6 5H8C8.55228 5 9 5.44772 9 6V8C9 8.55228 8.55228 9 8 9H6C5.44772 9 5 8.55228 5 8V6ZM16 3C14.3431 3 13 4.34315 13 6V8C13 9.65685 14.3431 11 16 11H18C19.6569 11 21 9.65685 21 8V6C21 4.34315 19.6569 3 18 3H16ZM15 6C15 5.44772 15.4477 5 16 5H18C18.5523 5 19 5.44772 19 6V8C19 8.55228 18.5523 9 18 9H16C15.4477 9 15 8.55228 15 8V6ZM3 16C3 14.3431 4.34315 13 6 13H8C9.65685 13 11 14.3431 11 16V18C11 19.6569 9.65685 21 8 21H6C4.34315 21 3 19.6569 3 18V16ZM6 15C5.44772 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V16C9 15.4477 8.55228 15 8 15H6ZM16 13C14.3431 13 13 14.3431 13 16V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V16C21 14.3431 19.6569 13 18 13H16ZM15 16C15 15.4477 15.4477 15 16 15H18C18.5523 15 19 15.4477 19 16V18C19 18.5523 18.5523 19 18 19H16C15.4477 19 15 18.5523 15 18V16Z"
        fill="black"
      />
    </svg>
  )
}

export default Explore
