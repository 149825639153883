import React from 'react'

function InfoIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10zm-.8429-16.122c.27-.252.594-.378.99-.378s.738.126 1.008.378c.252.252.396.576.396.972s-.144.738-.414.99-.594.378-.99.378-.72-.126-.99-.378c-.27-.27-.396-.594-.396-.99s.126-.72.396-.972zm-.7617 3.76216c.4779-.22532.9997-.34216 1.5281-.34216.8635 0 1.531.7579 1.4219 1.6146l-.7008 5.5c-.0666.5228.4135.949.9247.8208l.3293-.0826c.3054-.0766.6014.1544.6014.4692 0 .223-.1525.4172-.3692.47l-.9979.2431c-.455.1109-.9215.1669-1.3898.1669-.7122 0-1.2604-.629-1.1631-1.3345l.7213-5.2309c.0882-.6398-.5471-1.1365-1.1467-.8966l-.37379.1496C9.40684 11.3372 9 11.0618 9 10.659c0-.2204.12719-.421.32654-.5149l1.06886-.50394z"
        fill="currentColor"
      />
    </svg>
  )
}

export default InfoIcon
