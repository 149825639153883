import * as React from 'react'

function MapPinSolidIcon(props) {
  return (
    <svg
      width={12}
      height={14}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.43a4 4 0 118 0c0 1.285-.677 2.694-1.572 3.972-.862 1.232-1.863 2.254-2.428 2.792-.565-.538-1.566-1.56-2.428-2.792C2.677 8.124 2 6.715 2 5.431zM6 .098A5.333 5.333 0 00.667 5.431c0 1.702.871 3.391 1.812 4.736.954 1.363 2.051 2.471 2.641 3.03.496.47 1.264.47 1.76 0 .59-.559 1.687-1.667 2.64-3.03.942-1.345 1.813-3.034 1.813-4.736A5.333 5.333 0 006 .097zM4.933 5.431a1.067 1.067 0 112.134 0 1.067 1.067 0 01-2.134 0zM6 3.164a2.267 2.267 0 100 4.533 2.267 2.267 0 000-4.533z"
        fill="#000"
      />
    </svg>
  )
}

export default MapPinSolidIcon
