import * as React from 'react'

function SvgDividerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H10C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11H3Z"
        fill="currentColor"
      />
      <path
        d="M14 11C13.4477 11 13 11.4477 13 12C13 12.5523 13.4477 13 14 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H14Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDividerIcon
