import React from 'react'

function UserIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7c0-1.65685 1.3431-3 3-3s3 1.34315 3 3c0 .86197-.3609 1.90685-.9742 2.73188C13.4052 10.5669 12.6682 11 12 11c-.6682 0-1.4052-.4331-2.02585-1.26812C9.36092 8.90685 9 7.86197 9 7zm3-5C9.23858 2 7 4.23858 7 7c0 1.34717.53452 2.80229 1.36899 3.925C9.19604 12.0377 10.459 13 12 13s2.804-.9623 3.631-2.075C16.4655 9.80229 17 8.34717 17 7c0-2.76142-2.2386-5-5-5zM8.6 14.8c.44183-.3314.53137-.9582.2-1.4-.33137-.4418-.95817-.5314-1.4-.2l-3.2 2.4C3.44458 16.1666 3 17.0557 3 18v1c0 1.6569 1.34315 3 3 3h12c1.6569 0 3-1.3431 3-3v-.5925c0-.8651-.3734-1.688-1.0245-2.2577l-3.317-2.9024c-.4156-.3637-1.0474-.3215-1.4111.0941-.3637.4156-.3215 1.0474.0941 1.4111l3.317 2.9024c.217.1898.3415.4642.3415.7525V19c0 .5523-.4477 1-1 1H6c-.55228 0-1-.4477-1-1v-1c0-.3148.14819-.6111.4-.8l3.2-2.4z"
        fill="#6D6D6D"
      />
    </svg>
  )
}

export default UserIcon
