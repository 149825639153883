import * as React from 'react'

function SvgQuoteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6 4.80006C9.04183 4.46869 9.13137 3.84189 8.8 3.40006C8.46863 2.95823 7.84183 2.86869 7.4 3.20006L6.6 3.80006C4.33375 5.49975 3 8.16724 3 11.0001V18.0001C3 19.6569 4.34315 21.0001 6 21.0001H8C9.65685 21.0001 11 19.6569 11 18.0001V13.0001C11 11.3432 9.65685 10.0001 8 10.0001H5.07173C5.33439 8.17955 6.30703 6.51979 7.8 5.40006L8.6 4.80006ZM5 18.0001V12.0001H8C8.55228 12.0001 9 12.4478 9 13.0001V18.0001C9 18.5523 8.55228 19.0001 8 19.0001H6C5.44772 19.0001 5 18.5523 5 18.0001ZM18.6 4.80006C19.0418 4.46869 19.1314 3.84189 18.8 3.40006C18.4686 2.95823 17.8418 2.86869 17.4 3.20006L16.6 3.80006C14.3337 5.49975 13 8.16724 13 11.0001V18.0001C13 19.6569 14.3431 21.0001 16 21.0001H18C19.6569 21.0001 21 19.6569 21 18.0001V13.0001C21 11.3432 19.6569 10.0001 18 10.0001H15.0717C15.3344 8.17955 16.307 6.51979 17.8 5.40006L18.6 4.80006ZM15 18.0001V12.0001H18C18.5523 12.0001 19 12.4478 19 13.0001V18.0001C19 18.5523 18.5523 19.0001 18 19.0001H16C15.4477 19.0001 15 18.5523 15 18.0001Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgQuoteIcon
