import React from 'react'

function ArrowLeftIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.293 12.707a1 1 0 010-1.414l7-7a1 1 0 011.414 1.414L5.414 11H21a1 1 0 110 2H5.414l5.293 5.293a1 1 0 01-1.414 1.414l-7-7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowLeftIcon
