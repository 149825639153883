import * as React from 'react'

function AlertCircleIcon(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.667a5.333 5.333 0 100 10.666A5.333 5.333 0 007 1.667zM.333 7a6.667 6.667 0 1113.334 0A6.667 6.667 0 01.333 7z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8.667A.667.667 0 117 10a.667.667 0 010-1.333zm.667-1.334a.667.667 0 01-1.334 0v-3a.667.667 0 011.334 0v3z"
        fill="#000"
      />
    </svg>
  )
}

export default AlertCircleIcon
