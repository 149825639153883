import React from 'react'

function EditContainedIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.586 4a2 2 0 012.828 0l3.536 3.536a2 2 0 010 2.828l-9.607 9.607a2 2 0 01-1.414.585H5.393a1 1 0 01-1-1v-4.535a2 2 0 01.586-1.414L14.586 4z"
        fill="#000"
      />
    </svg>
  )
}

export default EditContainedIcon
