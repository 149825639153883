import React from 'react'

function ArrowRightIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.707 12.707a1 1 0 000-1.414l-7-7a1 1 0 10-1.414 1.414L18.586 11H3a1 1 0 100 2h15.586l-5.293 5.293a1 1 0 001.414 1.414l7-7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowRightIcon
