import * as React from 'react'

function RandomTalk(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.823 3.177A5.157 5.157 0 002.685 9.36c.092.164.111.36.052.539l-.683 2.048 2.048-.683a.667.667 0 01.539.053 5.157 5.157 0 006.182-8.138zm-8.236-.943A6.49 6.49 0 114.252 12.62L1.21 13.633a.667.667 0 01-.844-.844l1.014-3.04a6.492 6.492 0 011.206-7.515z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667 6.333c0-.368.298-.666.666-.666h5.334a.667.667 0 110 1.333H4.333a.667.667 0 01-.666-.667zM3.667 9c0-.368.298-.666.666-.666H7a.667.667 0 110 1.333H4.333A.667.667 0 013.667 9z"
        fill="#000"
      />
    </svg>
  )
}

export default RandomTalk
