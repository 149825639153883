import React from 'react'

function EditIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.586 4a2 2 0 012.828 0l3.536 3.536a2 2 0 010 2.828l-9.607 9.607a2 2 0 01-1.414.585H5.393a1 1 0 01-1-1v-4.535a2 2 0 01.586-1.414L14.586 4zm4.95 4.95L16 5.414l-9.607 9.607v3.535H9.93l9.606-9.606z"
        fill="currentColor"
      />
    </svg>
  )
}

export default EditIcon
