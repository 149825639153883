import * as React from 'react'

function CropIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.60024 18.3001C6.10319 18.3001 5.70024 17.8972 5.70024 17.4001V7.50006H3.0001C2.50304 7.50006 2.1001 7.09712 2.1001 6.60006C2.1001 6.103 2.50304 5.70006 3.0001 5.70006H5.70024V3.0001C5.70024 2.50304 6.10319 2.1001 6.60024 2.1001C7.0973 2.1001 7.50024 2.50304 7.50024 3.0001V5.70006H17.4001C17.8972 5.70006 18.3001 6.103 18.3001 6.60006V16.5001H21.0002C21.4973 16.5001 21.9002 16.903 21.9002 17.4001C21.9002 17.8972 21.4973 18.3001 21.0002 18.3001H18.3001V21.0001C18.3001 21.4971 17.8972 21.9001 17.4001 21.9001C16.903 21.9001 16.5001 21.4971 16.5001 21.0001V18.3001H6.60024ZM16.5001 16.5001V7.50006H7.50024V16.5001H16.5001Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width={24} height={24} fill="currentColor" />
      </g>
    </svg>
  )
}

export default CropIcon
