import React from 'react'

function ImageAddIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-1.8a.7.7 0 100-1.4.7.7 0 000 1.4z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6a3 3 0 013-3h12a3 3 0 013 3v7.86a1 1 0 01-1.694.719l-2.51-2.422a1 1 0 00-1.438.051L9.245 19H12a1 1 0 110 2H6a3 3 0 01-3-3V6zm3.555 13l7.317-8.13a3 3 0 014.312-.152l.816.787V6a1 1 0 00-1-1H6a1 1 0 00-1 1v12a1 1 0 001 1h.555z"
        fill="white"
      />
      <path
        d="M20.5 17a1 1 0 10-2 0v1.5H17a1 1 0 100 2h1.5V22a1 1 0 102 0v-1.5H22a1 1 0 100-2h-1.5V17z"
        fill="white"
      />
    </svg>
  )
}

export default ImageAddIcon
