import * as React from 'react'

function SvgImageIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM6 5C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H6.68917L13.9208 10.9648C15.0323 9.72983 16.9521 9.67934 18.127 10.8542L19 11.7272V6C19 5.44772 18.5523 5 18 5H6ZM18 19H9.11083L15.2588 12.169C15.6804 11.7005 16.4086 11.6814 16.8542 12.127L19 14.2728V18C19 18.5523 18.5523 19 18 19ZM9.7 9C9.7 9.3866 9.3866 9.7 9 9.7C8.6134 9.7 8.3 9.3866 8.3 9C8.3 8.6134 8.6134 8.3 9 8.3C9.3866 8.3 9.7 8.6134 9.7 9ZM11.5 9C11.5 10.3807 10.3807 11.5 9 11.5C7.61929 11.5 6.5 10.3807 6.5 9C6.5 7.61929 7.61929 6.5 9 6.5C10.3807 6.5 11.5 7.61929 11.5 9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgImageIcon
