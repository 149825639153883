function SvgComponent(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.667a5.333 5.333 0 100 10.666A5.333 5.333 0 007 1.667zM.333 7a6.667 6.667 0 1113.334 0A6.667 6.667 0 01.333 7z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3c.368 0 .667.298.667.667V7.31L9.37 8.445a.667.667 0 01-.74 1.11l-2-1.334a.667.667 0 01-.297-.554v-4C6.333 3.298 6.632 3 7 3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
