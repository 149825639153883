import * as React from 'react'

function SvgMarkerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2929 3.72172C16.855 2.15962 19.3877 2.15962 20.9497 3.72172C22.5118 5.28382 22.5118 7.81648 20.9497 9.37857L11.0503 19.2781C9.50446 20.8239 7.40791 21.6923 5.22182 21.6923L4.96271 21.6923C3.86724 21.6923 2.97918 20.8042 2.97918 19.7088L2.97918 19.4496C2.97918 17.2636 3.8476 15.167 5.3934 13.6212L13.8787 5.13593L13.1716 4.42883L8.92893 8.67147C8.53841 9.06199 7.90524 9.06199 7.51472 8.67147C7.12419 8.28094 7.12419 7.64778 7.51472 7.25725L11.7574 3.01461C12.5384 2.23356 13.8047 2.23356 14.5858 3.01461L15.2929 3.72172ZM19.5355 5.13593C18.7545 4.35489 17.4882 4.35489 16.7071 5.13593L11.0503 10.7928L13.8787 13.6212L19.5355 7.96436C20.3166 7.18331 20.3166 5.91698 19.5355 5.13593ZM12.4645 15.0354L9.63604 12.207L6.80761 15.0354C5.63689 16.2062 4.97918 17.794 4.97919 19.4496V19.6923L5.22182 19.6923C6.87748 19.6923 8.46532 19.0346 9.63604 17.8639L12.4645 15.0354Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMarkerIcon
