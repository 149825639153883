import * as React from 'react'

function SvgBoldIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5.25C5.75 4.00736 6.75736 3 8 3H13.875C16.5674 3 18.75 5.18261 18.75 7.875C18.75 9.16281 18.2507 10.334 17.435 11.2055C18.8339 12.1759 19.75 13.7935 19.75 15.625C19.75 18.5935 17.3435 21 14.375 21H8C6.75736 21 5.75 19.9926 5.75 18.75V5.25ZM8.25 12.75V18.5H14.375C15.9628 18.5 17.25 17.2128 17.25 15.625C17.25 14.0372 15.9628 12.75 14.375 12.75H8.25ZM8.25 10.25H13.875C15.1867 10.25 16.25 9.18668 16.25 7.875C16.25 6.56332 15.1867 5.5 13.875 5.5H8.25V10.25Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBoldIcon
