import React from 'react'

function NotificationIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M5.50619 7.87156C5.85099 4.5448 8.64767 2 12.0002 2c3.3523 0 6.1489 2.54451 6.4939 5.87108.1445 1.39334.2993 2.83992.4339 3.99692.0673.5791.1291 1.0812.1817 1.466.0263.1927.0497.3514.0697.4733.01.0608.0186.1091.0257.1458.0067.034.0104.0497.0111.0523.0103.0386.053.1369.197.3188.1133.1432.2411.2811.399.4515.0451.0487.0927.1.1431.1549.2047.2229.4559.5047.6536.82.2006.3199.3911.7433.3911 1.2494 0 .425-.1091.8102-.3242 1.1328-.2109.3164-.485.5148-.7286.6366-.2891.1446-.6208.2297-.9452.2306H5.5s-.55067.0158-.82065-.0292c-.15924-.0266-.38685-.0815-.62656-.2014-.24363-.1218-.51768-.3202-.72859-.6366C3.10912 17.8102 3 17.425 3 17c0-.5061.19048-.9295.39111-1.2494.19773-.3153.44894-.5971.65369-.82.05049-.055.09813-.1064.14332-.1552.15787-.1703.28565-.3082.39898-.4514.144-.1819.18681-.28.1972-.3188.00063-.0026.00444-.0184.01105-.0523.00714-.0366.01575-.0849.02575-.1457.02002-.1219.04339-.2805.06971-.4732.05256-.3848.11435-.8868.1817-1.4658.13458-1.1569.28928-2.60336.43368-3.99664zM5.02235 17H18.9777c.0268-.0031.0207-.0153.0127-.0382-.011-.0318-.0331-.0805-.0759-.1486-.0913-.1457-.2307-.3103-.4322-.5298-.0339-.0368-.0704-.0761-.1087-.1174-.1643-.177-.3618-.3898-.5288-.6007-.2075-.2624-.446-.6146-.5606-1.0425-.0521-.1947-.1049-.5432-.1561-.9182-.0552-.4041-.1188-.9212-.1867-1.5054-.1361-1.1696-.2919-2.62584-.4366-4.02183C16.2647 5.76215 14.3199 4 12.0002 4 9.68035 4 7.73551 5.76235 7.49554 8.07774c-.14468 1.39593-.30039 2.85216-.43642 4.02156-.06796.5842-.13152 1.1013-.18671 1.5054-.05122.375-.10408.7236-.15634.9185-.11471.4278-.35322.7799-.56082 1.0422-.16693.2108-.36436.4235-.52872.6005-.03833.0413-.07486.0807-.10879.1176-.20152.2194-.34094.384-.43228.5297-.04274.0681-.06486.1168-.07589.1486-.00856.0246-.01498.035.01278.0382z"
        fill="currentColor"
      />
      <path d="M11 19h2v2c0 .5523-.4477 1-1 1s-1-.4477-1-1v-2z" fill="currentColor" />
    </svg>
  )
}

export default NotificationIcon
