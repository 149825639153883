import * as React from 'react'

function SvgTextHeadingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M4 5.5C4 4.39543 4.89543 3.5 6 3.5H18C19.1046 3.5 20 4.39543 20 5.5V7C20 7.55228 19.5523 8 19 8C18.4477 8 18 7.55228 18 7V5.5H13V18.5H14C14.5523 18.5 15 18.9477 15 19.5C15 20.0523 14.5523 20.5 14 20.5H12.0155C12.0104 20.5001 12.0052 20.5001 12 20.5001C11.9948 20.5001 11.9896 20.5001 11.9845 20.5H10C9.44772 20.5 9 20.0523 9 19.5C9 18.9477 9.44772 18.5 10 18.5H11V5.5H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V5.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTextHeadingIcon
