import * as React from 'react'

function SvgLinkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7782 4.22206C17.8256 2.26944 14.6598 2.26944 12.7071 4.22206L11.2929 5.63627C10.9024 6.0268 10.9024 6.65996 11.2929 7.05048C11.6835 7.44101 12.3166 7.44101 12.7071 7.05048L14.1214 5.63627C15.2929 4.4647 17.1924 4.4647 18.364 5.63627C19.5356 6.80784 19.5356 8.70734 18.364 9.87891L15.5356 12.7073C14.364 13.8789 12.4645 13.8789 11.2929 12.7073C10.9024 12.3168 10.2692 12.3168 9.87871 12.7073C9.48819 13.0979 9.48819 13.731 9.87871 14.1216C11.8313 16.0742 14.9972 16.0742 16.9498 14.1216L19.7782 11.2931C21.7308 9.3405 21.7308 6.17468 19.7782 4.22206ZM8.4645 11.2931C9.63607 10.1216 11.5356 10.1216 12.7071 11.2931C13.0977 11.6836 13.7308 11.6836 14.1214 11.2931C14.5119 10.9026 14.5119 10.2694 14.1214 9.87891C12.1687 7.92629 9.00291 7.92629 7.05029 9.87891L4.22186 12.7073C2.26924 14.66 2.26924 17.8258 4.22186 19.7784C6.17448 21.731 9.34031 21.731 11.2929 19.7784L12.7071 18.3642C13.0977 17.9737 13.0977 17.3405 12.7071 16.95C12.3166 16.5595 11.6835 16.5595 11.2929 16.95L9.87871 18.3642C8.70714 19.5358 6.80765 19.5358 5.63607 18.3642C4.4645 17.1926 4.4645 15.2931 5.63607 14.1216L8.4645 11.2931Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLinkIcon
