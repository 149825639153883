import * as React from 'react'

function SettingIcon(props) {
  return (
    <svg
      width={14}
      height={12}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.939 1.793a.667.667 0 00-.588-.346l-4.706.02a.667.667 0 00-.582.345L1.937 5.68c-.11.2-.11.442-.001.641l2.125 3.885a.667.667 0 00.588.347l4.706-.02a.667.667 0 00.582-.346l2.126-3.868c.11-.2.11-.441.001-.64L9.94 1.792zM9.346.113a2 2 0 011.763 1.04l2.125 3.885a2 2 0 01-.002 1.924l-2.127 3.868a2 2 0 01-1.744 1.036l-4.707.02a2 2 0 01-1.763-1.04L.766 6.961a2 2 0 01.002-1.923L2.895 1.17A2 2 0 014.64.134l4.707-.02z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.6a1.4 1.4 0 100 2.8 1.4 1.4 0 000-2.8zM4.4 6a2.6 2.6 0 115.2 0 2.6 2.6 0 01-5.2 0z"
        fill="#000"
      />
    </svg>
  )
}

export default SettingIcon
