import React from 'react'

const LockIcon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_125:3641"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={18}
        height={18}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 1.5C6.92893 1.5 5.25 3.17893 5.25 5.25V7.5H4.5C3.67157 7.5 3 8.17157 3 9V15C3 15.8284 3.67157 16.5 4.5 16.5H13.5C14.3284 16.5 15 15.8284 15 15V9C15 8.17157 14.3284 7.5 13.5 7.5H12.75V5.25C12.75 3.17893 11.0711 1.5 9 1.5ZM11.25 7.5V5.25C11.25 4.00736 10.2426 3 9 3C7.75736 3 6.75 4.00736 6.75 5.25V7.5H11.25Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_125:3641)">
        <rect width="18" height="18" fill="#CCCCCC" />
      </g>
    </svg>
  )
}

export default LockIcon
