import React from 'react'

function ChevronDownIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M4.29289 7.79289c-.39052.39053-.39052 1.02369 0 1.41422l7.00001 6.99999c.3905.3905 1.0237.3905 1.4142 0l7-6.99999c.3905-.39053.3905-1.02369 0-1.41422-.3905-.39052-1.0237-.39052-1.4142 0L12 14.0858 5.70711 7.79289c-.39053-.39052-1.02369-.39052-1.41422 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChevronDownIcon
