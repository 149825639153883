import React from 'react'

function ImportantIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM10.973 7.655a1.028 1.028 0 112.054 0l-.314 6.386a.714.714 0 01-1.426 0l-.314-6.386zm.155 8.265c.224-.224.512-.336.864-.336s.656.112.896.336c.224.224.352.512.352.864s-.128.656-.352.88a1.3 1.3 0 01-.896.336c-.352 0-.64-.128-.864-.352-.256-.224-.368-.512-.368-.864s.112-.64.368-.864z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ImportantIcon
