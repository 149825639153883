import * as React from 'react'

function SvgQuestionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM9.54 7.508C10.228 6.836 11.156 6.5 12.34 6.5C13.38 6.5 14.228 6.772 14.868 7.348C15.508 7.908 15.828 8.676 15.828 9.652C15.828 10.452 15.62 11.108 15.236 11.62C15.092 11.78 14.628 12.212 13.86 12.884C13.572 13.108 13.364 13.38 13.22 13.668C13.1596 13.7887 13.1139 13.9116 13.0795 14.0363C12.9444 14.5261 12.5681 14.948 12.06 14.948C11.5519 14.948 11.1187 14.5331 11.192 14.0303C11.236 13.7284 11.316 13.4337 11.46 13.156C11.668 12.724 12.292 12.052 13.332 11.124L13.524 10.9C13.812 10.548 13.956 10.164 13.956 9.764C13.956 9.236 13.796 8.82 13.508 8.516C13.204 8.212 12.772 8.068 12.228 8.068C11.54 8.068 11.044 8.276 10.724 8.708C10.5868 8.89368 10.4862 9.12009 10.4202 9.38724C10.2994 9.87623 9.91568 10.292 9.412 10.292C8.90832 10.292 8.48724 9.8801 8.57497 9.38412C8.70961 8.6229 9.02716 7.99752 9.54 7.508ZM11.188 16.068C11.412 15.844 11.7 15.732 12.052 15.732C12.404 15.732 12.708 15.844 12.948 16.068C13.172 16.292 13.3 16.58 13.3 16.932C13.3 17.284 13.172 17.588 12.948 17.812C12.692 18.036 12.404 18.148 12.052 18.148C11.7 18.148 11.412 18.02 11.188 17.796C10.932 17.572 10.82 17.284 10.82 16.932C10.82 16.58 10.932 16.292 11.188 16.068Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgQuestionIcon
