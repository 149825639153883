import React from 'react'

function CouponIcon(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.243 11.67a2 2 0 010-2.828l6.6-6.6a2 2 0 012.828 0l1.414 1.415c.26.26.26.682 0 .943a.667.667 0 00.943.943c.26-.26.682-.26.943 0l1.414 1.414a2 2 0 010 2.828l-6.6 6.6a2 2 0 01-2.828 0L5.542 14.97a.667.667 0 010-.943.667.667 0 10-.942-.943.667.667 0 01-.943 0l-1.414-1.414zm.942-1.885a.667.667 0 000 .943l1.024 1.023a2 2 0 012.667 2.668L7.9 15.442c.26.26.683.26.943 0l6.6-6.6a.667.667 0 000-.942l-1.023-1.024A2 2 0 0111.75 4.21l-1.023-1.023a.667.667 0 00-.943 0l-6.6 6.6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.485 10.257a.667.667 0 010-.943l2.829-2.829a.667.667 0 11.943.943l-2.829 2.829a.667.667 0 01-.943 0zM8.37 12.142a.667.667 0 010-.943l2.83-2.828a.667.667 0 11.942.943l-2.828 2.828a.667.667 0 01-.943 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CouponIcon
