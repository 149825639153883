import * as React from 'react'

function StarIcon(props) {
  return (
    <svg
      width={14}
      height={13}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.127 1.31a1 1 0 011.793 0l1.407 2.852 3.147.458a1 1 0 01.554 1.705l-2.277 2.22.538 3.134a1 1 0 01-1.45 1.054l-2.816-1.48-2.814 1.48a1 1 0 01-1.451-1.054l.537-3.134-2.277-2.22a1 1 0 01.555-1.705l3.146-.458 1.408-2.851zm.896 1.197L5.76 5.067a.667.667 0 01-.502.364l-2.825.41 2.044 1.994a.667.667 0 01.192.59l-.483 2.814L6.713 9.91a.667.667 0 01.62 0l2.528 1.329-.483-2.814a.667.667 0 01.192-.59l2.044-1.993-2.825-.41a.667.667 0 01-.502-.365l-1.264-2.56z"
        fill="#000"
      />
    </svg>
  )
}

export default StarIcon
