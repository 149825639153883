import * as React from 'react'

function SvgTextNormalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M5 9.5C5 8.39543 5.89543 7.5 7 7.5H17C18.1046 7.5 19 8.39543 19 9.5V11C19 11.5523 18.5523 12 18 12C17.4477 12 17 11.5523 17 11V9.5H13V18.5H14C14.5523 18.5 15 18.9477 15 19.5C15 20.0523 14.5523 20.5 14 20.5H12.0155C12.0104 20.5001 12.0052 20.5001 12 20.5001C11.9948 20.5001 11.9896 20.5001 11.9845 20.5H10C9.44772 20.5 9 20.0523 9 19.5C9 18.9477 9.44772 18.5 10 18.5H11V9.5H7V11C7 11.5523 6.55228 12 6 12C5.44772 12 5 11.5523 5 11V9.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTextNormalIcon
